<script lang="ts">
  import { css } from '@amedia/brick-tokens';

  import BrickImage from '../brick-image/brick-image.svelte';

  import OnboardingHelper from './onboarding-helper.svelte';
  import { isDirty, isShowingSitesSelector } from './onboarding-stores';

  import SitesSelector from '$components/common/sites-selector/sites-selector.svelte';
  import i18n from '$i18n/i18n.js';
  import {
    saveOnboardedPreferences,
    savePublicationPreferences,
  } from '$utils/queries.js';
  import type { UserStore } from '$stores/user-store';

  export let user = { uuid: '' } as UserStore['data'];
  export let primarySite: string;
  export let isDesktop: boolean;

  const onboardingWrapperStyle = css({
    boxSizing: 'border-box',
    maxWidth: '480px',
    margin: '$x6 auto $x4',
    padding: '0 $x4',

    "form[name='preferences']": {
      marginInline: 'calc(-1* $x4)',
    },

    variants: {
      isShowingSitesSelector: {
        true: {
          paddingBottom: '$x14',
        },
      },
      isDesktop: {
        true: {
          marginTop: '115px',
          maxWidth: '520px',
          width: '100%',
        },
        false: {
          height: '100%',
        },
      },
    },
  });

  const onboardingImageStyle = css({
    display: 'block',
    margin: '0 0 $x8 0',
    '& div > brick-image-v3': {
      '--brick-image-object-fit': 'contain',
    },

    variants: {
      isDesktop: {
        true: {
          position: 'relative',
          top: 'calc(50vh - 115px)',
          transform: 'translateY(-50%)',
          maxWidth: 520,
        },
        false: {
          '& div > brick-image-v3': {
            '--brick-image-height': 200,
          },
        },
      },
    },
  });

  const onboardingHeadingStyle = css({
    fontstyle: 'baseTitlepieceM',
    margin: '0 0 $x4 0',
  });

  async function handleSitesSelectorCancel() {
    $isShowingSitesSelector = false;

    if (user?.preferredSites.length === 0) {
      await savePublicationPreferences({
        siteKey: primarySite,
        preferred: true,
      });
    }

    saveOnboardedPreferences(uuid, true);
  }

  async function onUpdate(event: CustomEvent) {
    const { siteKey, checked } = event.detail;

    await savePublicationPreferences({ siteKey, preferred: checked });
  }

  $: uuid = user?.uuid || '';
</script>

<main
  id="main-content-begin"
  class={onboardingWrapperStyle({
    isShowingSitesSelector: $isShowingSitesSelector,
    isDesktop,
  })}
>
  {#if !$isShowingSitesSelector}
    <BrickImage
      class={onboardingImageStyle({ isDesktop })}
      src={isDesktop
        ? 'https://cdn.sanity.io/images/ogtltof7/production/af6e7e2e5362cf5fe5366ab9e41e0284396d90c6-640x360.png'
        : 'https://cdn.sanity.io/images/ogtltof7/development/6e6d19f356702bae93b91a7d1c563dad973b392e-320x180.png'}
      alt=""
    />

    {#if !isDesktop}
      <OnboardingHelper {uuid} />
    {/if}
  {:else}
    {#if !isDesktop}
      <h1 class={onboardingHeadingStyle()}>
        {$i18n.t('onboarding.splash.heading-2')}
      </h1>
    {/if}
    <SitesSelector
      {isDesktop}
      {isDirty}
      isOnboarding
      visible={$isShowingSitesSelector}
      on:update={onUpdate}
      on:close={handleSitesSelectorCancel}
    />
  {/if}
</main>
