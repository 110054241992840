<script lang="ts">
  import { css } from '@amedia/brick-tokens';

  import BrickImage from '../brick-image/brick-image.svelte';

  import Card from '$components/style/card/card.svelte';

  export let title: string;
  export let description: string | undefined;
  export let author: string;
  export let cover: string | undefined;
  export let href: string;
  export let duration: number;

  const episodeMinutes = Number(duration) / 60;

  const episodeHours = Math.floor(episodeMinutes / 60);

  const minutesWithoutHours = Math.abs(
    episodeHours * 60 - Math.round(episodeMinutes)
  );

  const minutesString = minutesWithoutHours ? minutesWithoutHours + 'min' : '';

  const hoursString = episodeHours ? episodeHours + 't ' : '';

  const episodeDuration = !(hoursString || minutesString)
    ? duration + ' sekunder'
    : hoursString + minutesString;

  const linkStyle = css({
    color: 'black',
    textDecoration: 'none',
  });

  const cardStyle = css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    isolation: 'isolate',
  });

  const textBoxStyle = css({
    padding: '$x14 $x6 $x6',
    userSelect: 'none',
    border: '2px solid #f4efe9',
    borderRadius: '$baseXl',
    height: '100%',
  });

  const headerStyle = css({
    fontstyle: 'baseHeadlineL',
    textWrap: 'balance',
    margin: '0px',
  });

  const subtitleStyle = css({
    marginBlock: '$x2 $x4',
    textWrap: 'balance',
    fontstyle: 'baseMetaM',
  });

  const descriptionStyle = css({
    color: 'rgba(0, 0, 0, 0.7)',
    fontstyle: 'baseBodyS',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    display: '-webkit-box',
    margin: '0px',

    variants: {
      lineClamps: {
        small: {
          '-webkit-line-clamp': 2,
        },
        medium: {
          '-webkit-line-clamp': 3,
        },
        large: {
          '-webkit-line-clamp': 4,
        },
        none: {
          display: 'none',
        },
      },
    },
  });

  const imageStyle = css({
    '& brick-image-v3 > img': {
      borderRadius: '$baseXl',
      border: '2px solid #f4efe9',
      boxSizing: 'border-box',
    },
    display: 'block',
    width: '300px',
    marginBottom: 'calc(-1 * $x8)',
    zIndex: 1,
  });

  const getLineClamps = (height: number) => {
    if (height < 50) {
      return 'large';
    }
    if (height < 80) {
      return 'medium';
    }
    if (height < 100) {
      return 'small';
    }
    return 'none';
  };

  $: clientHeight = 0;
</script>

<a
  class={linkStyle()}
  {href}
  data-adp-clickLabel="untold"
  data-adp-clickValue={title}
>
  <Card padding="0px" height="100%">
    <article class={cardStyle()}>
      {#if cover}
        <BrickImage
          class={imageStyle()}
          src={cover}
          alt={'Cover bilde til ' + title + ' av ' + author}
        />
      {/if}
      <div class={textBoxStyle()}>
        <div bind:clientHeight>
          <h3 class={headerStyle()}>
            {title}
          </h3>
          <div class={subtitleStyle()}>
            {#if duration}
              {episodeDuration}
              &bull;
            {/if}
            {author}
          </div>
        </div>
        {#if description}
          <p
            class={descriptionStyle({
              lineClamps: getLineClamps(clientHeight),
            })}
          >
            {description}
          </p>
        {/if}
      </div>
    </article>
  </Card>
</a>
